.email-list {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-main-content);
  // border-radius: 8px;
  margin-top: -11px !important;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 140px);

  @media only screen and (max-width: 1750px) {
    max-height: calc(100vh - 165px);
  }
}

.email-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  background-color: var(--whiteColor);
  border-bottom: 1px solid #e8eae8;
  transition: background-color 0.2s ease;

  .profile-icon {
    background-color: #d4d5fb;
    // padding: 8px;
    border-radius: 100%;
    color: var(--blackColor) !important;
    font-weight: 500;
    font-size: var(--commonFont-medium) !important;
    margin: 0px 10px 0 0;
    min-width: 35px !important;
    min-height: 35px !important;
    max-width: 35px !important;
    max-height: 35px !important;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1366px) {
      min-width: 22px !important;
      min-height: 22px !important;
      max-width: 22px !important;
      max-height: 22px !important;
      font-size: var(--commonFont-small) !important;
    }
  }

  &:hover {
    background-color: #eeeef3;
    cursor: pointer;
  }

  &.active {
    background-color: #eeeef3;
  }

  .email-item-info {
    display: flex;
    flex-direction: column;
  }

  .email-address {
    color: var(--blackColor);
    font-size: var(--commonFont-medium);
    font-weight: 500;
  }

  .email-subject {
    font-size: var(--commonFont-medium);
  }

  .email-item-date {
    font-size: var(--commonFont-medium);
    color: #797979;
    text-align: right;
  }
}

// Undefined Order Detail Start

.email-detail {
  padding: 12px;
  // background-color: #f9f9f9;
  border: 1px solid var(--cardborderColor);
  border-radius: 8px;
}

.header-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--cardborderColor);

  .email-item-info {
    font-size: var(--commonFont) !important;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .subject-title {
    display: flex;
    gap: 6px;

    .label {
      font-weight: bold;
      color: var(--textColor);
      min-width: 60px !important;
    }

    .value {
      color: #555;
    }
  }

  .email-date {
    font-size: 0.9rem;
    color: #888;
    text-align: right;
  }
}

.email-body {
  font-size: var(--commonFont-medium);
  color: #444;
  line-height: 1.6;
  margin-top: 20px;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 320px);

  @media only screen and (max-width: 1560px) {
    max-height: calc(100vh - 277px);
  }

  p {
    margin-bottom: 12px;
  }
}

// Undefined Order Detail End



.email-body-sec {
  max-height: calc(100vh - 337px);
  overflow: hidden;
  overflow-y: auto;

  @media only screen and (max-width: 1750px) {
    max-height: calc(100vh - 290px);
  }
}

.left-tab-section-list {
  height: 100%;

  .card {
    height: calc(100% - 45px);

    @media only screen and (max-width: 1750px) {
      height: calc(100vh - 100px);
    }
  }
}