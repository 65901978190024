.table-hover {
  table {
    tbody {
      tr {
        &:hover {
          background: #f2edf3b0;
        }
      }
    }
  }
}

.table-striped {
  table {
    tbody {
      tr {
        border-bottom: 1px solid var(--tableBorderColor) !important;
      }
    }
  }
}

.table-bordered {
  table {
    thead {
      background: var(--themeColorLight);

      tr {
        th {
          border: 1px solid var(--inputBorder);
        }
      }
    }

    tbody {
      tr {
        td {
          border: 1px solid var(--inputBorder);
        }
      }
    }
  }
}

// Table border-table-simple End

//dynamic grid

// .dynamic-grid {
.final-molgrid-section {
  table {
    width: 100%;
    caption-side: bottom;
    border-collapse: collapse;
    overflow: hidden;

    thead {
      display: block;
      width: 100%;
      color: white !important;
      background: var(--themeColorLight);
      border-bottom: 1px solid var(--tableBorderColor);

      tr {
        display: flex;
        width: 100%;
        text-transform: capitalize;

        th {
          padding: 12px 10px 10px 19px;
          font-size: var(--commonFont);
          color: var(--blackColor);
          text-transform: uppercase;
          font-weight: 600;
          display: flex;
          align-items: center;

          .heading-shorting {
            position: relative;

            .shorting-part {
              display: inline-block;

              button {
                background: transparent;
                border: none;
                outline: none;
                padding: 0 10px 0 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;

                span {
                  margin-right: 10px;
                  font-weight: 500;
                  padding: 0;
                  text-align: left;
                  text-transform: uppercase;
                  color: var(--blackColor);
                }

                img {
                  height: 14px;
                }
              }

              span {
                &.sort-priority-number {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: var(--themeColor);
                  position: absolute;
                  right: -29px;
                  top: -9px;
                  color: var(--whiteColor);
                  height: 18px;
                  width: 30px;
                  line-height: 18px;
                  font-size: var(--commonFont-extraSmall);
                  border-radius: 4px;

                  .short-count {
                    display: flex;
                    align-items: center;
                    margin-left: 3px;

                    .cancel-short {
                      margin-left: 3px;
                      background: #ffffff9c;
                      width: 12px;
                      height: 12px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 5px;
                      transition: 0.5s all;

                      i {
                        margin-left: 0;
                        margin-top: -1px;
                      }

                      &:hover {
                        background: var(--whiteColor);
                      }

                      img {
                        width: 6px;
                        height: 6px;
                      }

                      display: flex;
                      justify-content: center;
                      align-items: center;

                      svg {
                        width: 8px;

                      }

                    }
                  }
                }
              }

              &.short-column {
                button {
                  img {
                    &.order-icon {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }
          }

          &:last-child {
            // width: 150px;
            text-align: center;

            @media only screen and (max-width: 1750px) {
              justify-content: center;
            }
          }
        }
      }
    }

    tbody {
      display: block;
      max-height: calc(100vh - 395px);
      overflow-y: auto;
      width: 100%;
      overflow-x: hidden;

      tr {
        display: flex;
        width: 100%;
        border-bottom: 1px solid var(--tableBorderColor) !important;
        border-left: 0 !important;
        border-right: 0 !important;

        td {
          padding: 8px 10px 8px 20px;
          font-size: var(--commonFont-medium);
          align-self: center;

          .input-validation {
            .checkbox-part {
              .checkbox {
                margin-right: 0 !important;
              }
            }
          }

          &:last-child {
            // width: 150px;
            text-align: center;

            div {
              justify-content: center;
              align-items: center;

              &.input-validation {
                text-align: center;
              }

              a {
                &:last-child {
                  margin-right: 0;
                  align-items: center;
                  display: flex;
                }
              }
            }
          }

          .dropdown-action {
            position: relative;
            width: 100%;
            display: flex;

            .btn {
              padding: 5px 7px;
              min-width: unset;
              background: #e0d9ff;

              img {
                margin: 0;
                width: 10px;
                filter: brightness(0);
                -webkit-filter: brightness(0);
              }
            }

            .dropdown-list {
              position: absolute;
              background: var(--whiteColor);
              width: 90px;
              /* border: 1px solid var(--inputBorder); */
              z-index: 11;
              right: 0;
              transform: translateX(-50%);
              top: 110%;
              padding: 7px 0;
              display: none;
              box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
              border-radius: 3px;

              ul {
                padding: 0;
                list-style-type: none;
                margin: 0;

                li {
                  text-align: left;

                  a {
                    display: block;
                    width: 100%;
                    padding: 0 15px;
                    // border: 1px solid var(--inputBorder);
                    font-size: var(--commonFont-medium);
                    font-weight: 400;
                    text-align: left;
                    color: var(--textColor);

                    &:hover {
                      color: var(--themeColor);
                    }
                  }
                }
              }
            }

            &.openAction {
              .dropdown-list {
                display: block;
              }
            }
          }

          .input-field {
            // height: 25px;
            border-radius: 3px;
          }
        }
      }
    }

    tbody {
      tr {
        border-left: 1px solid var(--tableBorderColor) !important;
        border-right: 1px solid var(--tableBorderColor) !important;

        td {
          &:last-child {
            a {
              cursor: pointer;
              display: flex;
              align-items: center;

              img {
                min-height: 17px;
                min-width: 17px;
                max-height: 17px;
                max-width: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.5s all;
              }

              &:hover {
                img {
                  filter: brightness(0);
                }
              }
            }
          }

          span {
            border-radius: 2px;
            font-size: var(--commonFont-small);
            margin: 0 auto;
            padding: 4px 15px;
            text-align: center;
            margin: 0 auto;

            &.primary {
              background-color: var(--primaryColorLight);
              color: var(--primaryColor);
            }

            &.secondary {
              background-color: var(--secondaryColorLight);
              color: var(--secondaryColor);
            }

            &.danger {
              background-color: var(--dangerColorLight);
              color: var(--dangerColor);
            }

            &.warning {
              background-color: var(--warningColorLight);
              color: var(--warningColor);
            }

            &.info {
              background-color: var(--infoColorLight);
              color: var(--infoColor);
            }

            &.dark {
              background-color: var(--darkColorLight);
              color: var(--darkColor);
            }

            &.custom-input {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.customer-list-all {
  table {
    tbody {
      max-height: calc(100vh - 325px);

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 315px);
      }
    }
  }
}

.inactive-list-sec {
  table {
    tbody {
      max-height: calc(100vh - 355px);

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 305px);
      }

      @media only screen and (max-width: 1366px) {
        max-height: calc(100vh - 300px);
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  table {
    thead {
      tr {
        th {
          padding: 10px 15px;
          display: flex;
          align-items: center;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 6px 14px;

          &:last-child {
            a {
              img {
                min-height: 16px;
                min-width: 16px;
                max-height: 16px;
                max-width: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  table {
    thead {
      tr {
        th {
          padding: 7px 10px 5px;
        }
      }
    }

  }
}

.input-list-button {
  .table-pagination {
    display: none !important;
  }
}


.short-count {
  .component-iconify {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



// Table Accordion collapsible row Start
.collapsible-table {
  table {
    &.table {
      border-width: 1px;

      thead {
        background: var(--tableHeader);
        --bs-table-bg: var(--tableHeader) !important;

        tr {
          &.heading-row {
            background: var(--tableHeader) !important;
            --bs-table-bg: var(--tableHeader) !important;
          }

          th {
            border: 1px solid #808080;
            padding: 5px 10px;
            font-weight: normal;
            color: var(--whiteColor);
          }
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            --bs-table-bg: var(--tableRows);
            background: var(--tableRows);
          }

          td {
            padding: 5px 10px;
            border: 1px solid #808080;
            border-top-color: #cbdaf5;
            border-bottom-color: #cbdaf5;
            text-align: left;

            &.accordion-toggle {
              text-align: center;
              background: #cbdbf5;
              cursor: pointer !important;
              width: 36px;
              border-left: 1px solid #808080;
              border-right: 1px solid #808080;
              border-bottom: none;

              span {
                padding: 0;
              }
            }

            &:last-child {
              padding-bottom: 0;
              border-right: 1px solid #808080;
            }

          }

          .hiddenRow {
            padding: 0;
            border-top: none;

            .accordian-body {
              .table {
                margin-bottom: 0;
                border: none;

                tbody {
                  tr {
                    &:first-child {
                      td {
                        border-top: 0;
                      }
                    }

                    &:last-child {
                      td {
                        border-bottom: 0;
                      }
                    }

                    td {

                      &:first-child {
                        --bs-table-bg: #cbdbf5;
                        background: #cbdbf5;
                        width: 35px;
                        border: none;
                        border-right: 1px solid #808080;
                      }

                      &:last-child {
                        border-right: 0;
                      }
                    }

                  }
                }
              }
            }
          }

          &.activeRow {
            --bs-table-bg: #d3d7de;
            background: #d3d7de;

            .accordion-toggle {
              border-bottom: 0;
              border-right: 1px solid #808080;

              span {
                &:before {
                  transform: rotate(90deg);
                  -webkit-transform: rotate(90deg);
                  -moz-transform: rotate(90deg);
                  -ms-transform: rotate(90deg);
                  -o-transform: rotate(90deg);
                }
              }
            }

            td {

              border-bottom-color: #459e1d;
              border-right-color: #d3d7de;

              &:last-child {
                border-right: 1px solid #808080;
              }
            }
          }

          &.activeRow-sub {
            .hiddenRow {
              border-left: 1px solid #808080;
              border-top: 1px solid #cbdaf5;
              border-bottom: 0;
              border-right: 1px solid #808080;

              tr {
                td {
                  &:first-child {
                    border-right: 1px solid;
                  }
                }
              }
            }
          }

          &:last-child {
            td {
              border-bottom: 1px solid #808080;

              &.accordion-toggle {
                border: 1px solid #808080;
                border-top: 1px solid #cbdbf5;
              }

              .accordian-body {
                table {
                  tr {
                    td {
                      border-bottom: 0;
                    }
                  }
                }
              }

            }

            &.activeRow-sub {
              td {
                &:first-child {
                  border-bottom: 1px solid;
                }
              }
            }
          }
        }
      }
    }
  }

}

.table-custom {
  table {
    tr {
      background: var(--bg-main-content);

      th {
        font-weight: 600;
        text-transform: uppercase;
        background: var(--themeColorLight);

        &:nth-child(1) {
          width: 2%;
        }

        &:nth-child(2) {
          width: 30%;
          text-align: left;
        }

        &:nth-child(3) {
          width: 20%;
          text-align: left;
        }

        &:nth-child(4) {
          width: 20%;
          text-align: left;
        }

        &:nth-child(5) {
          width: 6%;
          text-align: left;

          .checkbox-part {
            justify-content: left;
          }
        }
      }

      &.parent-row {
        background: var(--whiteColor) !important;
        margin-bottom: 0;
        margin-top: 0;
        border-top: 1px solid var(--tableBorderColor) !important;
        border-bottom: 0 !important;
        &:last-child{
          border-bottom: 1px solid var(--tableBorderColor) !important;
        }
        td {
          &:nth-child(1) {
            width: 2%;
            text-align: center;
            padding-left: 10px;
            cursor: pointer;

            @media only screen and (max-width: 1650px) {
              padding: 0;

              .component-iconify {
                width: 10px !important;
                height: 10px !important;
              }
            }

            span {
              padding: 0;
            }
          }

          &:nth-child(2) {
            text-align: left;
          }

          &:nth-child(3) {
            text-align: center;

            .checkbox-part {
              justify-content: center;
            }
          }

          &:nth-child(4) {
            text-align: center;

            .checkbox-part {
              justify-content: center;
            }
          }

          &:nth-child(5) {
            text-align: center;

            .checkbox-part {
              justify-content: center;
            }
          }
        }

        &.collapsed {
          background-color: var(--lightGreen) !important;

          td {
            font-weight: 600;
          }
        }

      }
      margin-bottom: 5px;
      .sub-table {
        tr {
          background: var(--whiteColor) !important;
          border-left: 0 !important;
          border-right: 0 !important;
        }
      }

      &.child-row {
        &:nth-child(odd) {
          background-color: #dfe9f0;
        }
      }

      .first-td {
        border-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .component-iconify {
          width: 15px !important;
          height: 15px !important;
          display: flex !important;
          align-items: center;
          justify-content: center;
        }
      }

      td {
        text-align: Center;

        &.sub-table {
          padding: 0;
          width: 100%;
          background: var(--bg-main-content);
          padding: 0 10px 0 10px;

          table {
            width: 100%;
            border: none;
            margin: 10px 0;
            border: 1px solid #e6e6e8;
            border-bottom: 0;

            tr {
              margin-bottom: 0;
              th {
                padding: 8px 10px;
                background: #9898b490;
                border: 1px solid #e6e6e8;
                border-top: 0;
                border-bottom: 0;
                color: var(--blackColor);
                font-weight: 600;
                text-transform: uppercase;

                @media only screen and (max-width: 1024px) {
                  padding: 5px 8px;
                }

                &:first-child {
                  border-left: 0;
                  padding-left: 0px;
                }

                &:nth-child(1) {
                  text-align: left;
                }

                &:nth-child(2) {
                  text-align: left;
                }

                &:last-child {
                  text-align: center !important;
                  // border-right: 0 !important;
                }


              }

              td {
                border: none;
                border-right: 1px solid #e6e6e8;
                padding: 5px 10px 5px 10px;
                span{
                  margin-top: 0 !important;
                  margin-bottom: 0 !important;
                }
                &:first-child {
                  padding: 5px 0 5px 10px;
                }

                &:nth-child(1) {
                  text-align: center;
                }

                &:nth-child(2) {
                  text-align: center;

                  .checkbox-part {
                    justify-content: left;
                  }
                }

                &:nth-child(3) {
                  text-align: center;

                  .checkbox-part {
                    justify-content: left;
                  }
                }

                &:nth-child(4) {
                  text-align: center;

                  .checkbox-part {
                    justify-content: left;
                  }
                }

                &:last-child {
                  border: none;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }


  }
}

// Table Accordion collapsible row End