.order-sync-form {
  .form-field {
    margin-bottom: 12px;
  }
  .order-info {
    .order-title {
      color: var(--themeColor);
      text-transform: capitalize;
      font-size: var(--commonFont-large);
      font-weight: 600;
      white-space: nowrap;
      border-bottom: 1px solid var(--cardborderColor);
    }
    .order-formfield {
      margin-top: 10px;
      position: relative;
    }
  }
  .approval-info {
    .approval-info-header {
      background-color: var(--themeColor);
      color: var(--whiteColor);
      text-transform: capitalize;
      font-size: var(--commonFont-large);
      font-weight: 600;
      white-space: nowrap;
      padding: 6px 10px;
      border-radius: 5px;
      margin-top: 15px;
    }
    .approval-info-body {
      margin-top: 15px;
      .approval-container {
        padding: 15px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
          rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        background-color: #fbe8e8;
        margin-bottom: 15px;
        border-radius: 5px;
        .approval-formfield {
          .approval-form-label {
            font-size: var(--commonFont);
            color: var(--textColor);
            font-weight: 500;
            display: flex;
            align-items: end;
            .component-iconify {
              color: red;
              width: 25px !important;
              height: 25px !important;
              svg {
                width: 25px !important;
                height: 25px !important;
              }
            }
          }
        }
        .approval-btn {
          margin-top: 10px;
          display: flex;
          justify-content: end;
        }
      }
      .no-mismatch-found{
        border: 1px dashed var(--themeColor);
        border-radius: 5px;
      }
    }
  }
}
