.record-not-found{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  img{
    width: 100px;
  }
  h5{
    margin-top: 5px;
  }
}