.dropdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .dropdownwrapper-toggle {
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    padding: 0;
  }

  .dropdownwrapper-content {
    position: absolute; // Keep it absolute for dynamic positioning
    background-color: var(--whiteColor);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 1000;
    min-width: 200px;
    max-width: 300px;
    transition: opacity 0.2s ease, transform 0.2s ease;
    opacity: 1;

    &.hidden {
      opacity: 0;
      pointer-events: none;
      transform: scale(0.95);
    }

    &.visible {
      opacity: 1;
      transform: scale(1);
    }
  }
}
