//Dropdown Select Start
.custom-input {
    width: 100%;
}

.css-b62m3t-container {
    width: 100%;
}

.css-13cymwt-control {
    box-shadow: none !important;
    border: 1px solid var(--inputBorder) !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    width: 100%;
    min-height: 35px;
    height: 35px;
    overflow: hidden !important;
    overflow-y: auto !important;
}

.css-12zf3ro-control {
    &:hover {
        border-color: var(--inputBorderHover) !important;
        box-shadow: none !important;
        border-radius: 5px !important;
        -webkit-border-radius: 5px !important;
        -moz-border-radius: 5px !important;
        -ms-border-radius: 5px !important;
        -o-border-radius: 5px !important;
        width: 100%;
        height: 35px;
    }

}

.css-t3ipsp-control {
    height: 35px;
    box-shadow: none !important;
    border-color: var(--inputBorderHover) !important;
    border: 1px solid var(--inputBorderHover) !important;
}

.css-1hb7zxy-IndicatorsContainer {
    height: 30px !important;
    padding: 0 5px !important;

    .css-1xc3v61-indicatorContainer {
        padding: 5px !important;
    }
}


.css-1nmdiqs-menu {
    font-size: var(--commonFont) !important;
    z-index: 99;
}

.css-1jqq78o-placeholder {
    font-size: var(--commonFont) !important;
    line-height: normal !important;
}

//Dropdown Select End
.css-t3ipsp-control,
.css-13cymwt-control {
    min-height: 34px !important;
    height: 34px !important;
    overflow: hidden;
    overflow-y: auto;
}

.css-12zf3ro-control {
    &:hover {
        min-height: 34px !important;
        height: 34px !important;
    }
}

.css-1xc3v61-indicatorContainer {
    padding: 5px !important;
}

.css-1fdsijx-ValueContainer {
    padding: 0 5px !important;
}

.css-15lsz6c-indicatorContainer {
    padding: 5px !important;
}
.css-1u9des2-indicatorSeparator{
    padding: 0.5px;
}
@media only screen and (max-width: 1560px) {

    .css-t3ipsp-control,
    .css-13cymwt-control {
        min-height: 30px !important;
        height: 30px !important;
    }

    .css-12zf3ro-control {
        &:hover {
            min-height: 30px !important;
            height: 30px !important;
        }
    }

    .css-1hb7zxy-IndicatorsContainer {
        height: 28px !important;
    }

    .css-1xc3v61-indicatorContainer {
        padding: 3px !important;
    }

    .css-1fdsijx-ValueContainer {
        padding: 0 3px !important;
    }

    .css-15lsz6c-indicatorContainer {
        padding: 3px !important;
    }
}