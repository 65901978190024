/* src/components/ProgressBar.css */
.progress-container {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  height: 18px;
  .progress-bar {
    height: 100%;
    background-color: #4caf50;
    border-radius: 5px;
    transition: width 0.3s;
    font-size: var(--commonFont-small);
    color: var(--whiteColor);
  }
}
