.comments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.comment-box {
  padding: 0 12px 0 5px;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100% - 55px);
}
.comment-section {
  padding: 10px 0;
  display: flex;
  align-items: flex-start;

  .comment-avatar {
    background-color: #d4d5fb;
    border-radius: 100%;
    color: #000 !important;
    font-weight: 500;
    font-size: var(--commonFont-large) !important;
    margin: 0 0 0 4px;
    min-width: 32px !important;
    min-height: 32px !important;
    max-width: 32px !important;
    max-height: 32px !important;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .comment-container {
    color: var(--textColorLight);
    margin-left: 15px;
    width: 100%;
    background-color: #f0efef;
    padding: 10px 12px;
    border-radius: 5px;
    position: relative;
    word-break: break-all;

    &:before {
      content: "";
      position: absolute;
      top: 6px;
      left: -10px;
      border-style: solid;
      border-width: 10px 10px 12px 0;
      border-color: transparent #f0efef transparent transparent;
    }

    .top-section {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;

      .comment-title {
        b {
          color: var(--textColor);
        }
      }
    }
  }
}

.comment-post {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--cardborderColor);
  padding: 12px 12px 12px 6px;

  .comment-avatar {
    background: var(--gradient-sidebar);
    border-radius: 100%;
    color: var(--whiteColor) !important;
    font-weight: 500;
    font-size: var(--commonFont-large) !important;
    margin: 0 0 0 4px;
    min-width: 32px !important;
    min-height: 32px !important;
    max-width: 32px !important;
    max-height: 32px !important;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-field {
    margin-left: 14px;
    padding: 8px 12px;
    flex-grow: 1;
    border: 1px solid var(--borderGreyColor);
    border-radius: 5px;
    outline: none;
    width: auto !important;
    margin-right: 10px !important;
  }
}
