.email-management {
  display: flex;
  transition: all 0.3s ease;

  .card {
    margin-bottom: 10px !important;
    border-radius: 4px !important;
    padding: 0 !important;

    .card-body-sec {
      margin: 0 !important;
    }
  }
  .email-management-list,
  .email-management-detail {
    display: flex;
    flex-direction: column;
  }

  .email-management-list {
    width: 100%;
    transition: width 0.3s ease;

    .email-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 8px;

      .email-header-title {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        .back-icon {
          color: var(--themeColor);
        }
        .email-title {
          font-size: var(--commonFont);
          font-weight: 700;
          color: var(--themeColor);
        }
      }

      .email-filters {
        display: flex;
        gap: 15px;
        align-items: center;
      }
    }

    .email-list-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--cardborderColor);
      padding: 7px 10px;
      line-height: 1;
      cursor: pointer;

      &.unread {
        // background-color: #edf1fd;
        border-left: 3px solid transparent;
        border-left: 3px solid var(--themeColor);
        .email-list-body {
          .email-list-subject {
            color: var(--themeColor) !important;
          }
        }
      }

      &.read {
        background-color: var(--whiteColor);
        border-left: 3px solid transparent;
        .email-list-sender {
          font-weight: 300 !important;
        }
        .email-list-body {
          .email-list-subject {
            font-weight: 300 !important;
          }
          .email-list-description {
            font-weight: 300 !important;
          }
        }
        .email-list-date {
          font-weight: 300 !important;
        }
        //collapsible email list
        .collapsed-email-subject {
          font-size: var(--commonFont);
          color: var(--textColor2);
        }
        .collapsed-email-view {
          .collapsed-email-body {
            .collapsed-email-sender,
            .collapsed-email-subject,
            .collapsed-email-description {
              font-weight: 300 !important;
              font-size: var(--commonFont-medium);
            }
          }
          .collapsed-email-date {
            font-weight: 300;
            font-size: var(--commonFont-small);
          }
        }
      }

      &.checkFlag {
        background-color: #fffad2;
      }

      &.selected {
        // border-left: 3px solid var(--themeColor) !important;
        background-color: #edf1fd !important;
      }

      .email-list-flag {
        cursor: pointer;
        &.checkFlag {
          color: #ffbc3a;
        }
        &.uncheckFlag {
          color: var(--cardborderColor);
        }
        &:hover {
          color: #ffbc3a;
        }
      }

      .email-list-sender {
        width: 15%;
        padding: 0 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
        color: #202124;
        cursor: pointer;
        font-size: var(--commonFont-medium);
      }

      .email-list-body {
        width: 70%;
        padding: 0 15px;
        display: flex;
        align-items: center;
        cursor: pointer !important;
        font-size: var(--commonFont-medium);

        .email-list-subject {
          white-space: nowrap;
          font-weight: 700;
          color: #5f6368;
        }

        .email-list-body-hyphen {
          padding: 0 5px;

          .component-iconify {
            width: 10px !important;
          }
        }

        .email-list-description {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 700;
          color: #80868b;
        }
      }

      .email-list-delete-icon {
        color: var(--cardborderColor);
        transition: opacity 0.3s ease;
        cursor: pointer !important;
        padding-right: 6px;
        &:hover {
          color: red;
        }
      }

      .email-list-date {
        width: 10%;
        padding: 0 15px;
        display: flex;
        justify-content: flex-end;
        white-space: nowrap;
        font-weight: 700;
        color: #5f6368;
        cursor: pointer;
        font-size: var(--commonFont-small);
      }
    }

    .email-management-list-scroll {
      .card {
        .card-body-sec {
          box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
            rgb(209, 213, 219) 0px 0px 0px 1px inset;
          overflow: hidden;
          overflow-y: auto;
          max-height: calc(100vh - 170px);
        }
      }
    }

    &.collapsed {
      width: 25%;
      margin-bottom: 0 !important;
      .unread {
        background-color: white !important;
        &.selected {
          background-color: #edf1fd !important;
        }
        &.checkFlag {
          background-color: #fffad2 !important;
        }
        .collapsed-email-view {
          .collapsed-email-body {
            .collapsed-email-subject {
              color: var(--themeColor) !important;
            }
          }
        }
      }
      .read {
        background-color: white !important;
        &.selected {
          background-color: #edf1fd !important;
        }
        &.checkFlag {
          background-color: #fffad2 !important;
        }
      }
      .email-list-container {
        padding: 4px 6px;
      }
    }

    .collapsed-email-view {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .collapsed-email-body {
        width: 72%;
        cursor: pointer;
        line-height: 1.2;

        .collapsed-email-sender,
        .collapsed-email-subject,
        .collapsed-email-description {
          max-width: 270px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 700;
          color: var(--textColor);
        }
        .collapsed-email-sender {
          font-size: var(--commonFont-medium);
        }
        .collapsed-email-subject {
          font-size: var(--commonFont);
          color: #5f6368;
        }
        .collapsed-email-description {
          color: var(--commonFont-small);
          color: #80868b;
        }
      }

      .collapsible-icon-container {
        display: flex;
        flex-direction: column;
        gap: 6px;
        .collapsed-email-flag {
          cursor: pointer;
          &.checkFlag {
            color: #ffbc3a;
          }
          &.uncheckFlag {
            color: var(--cardborderColor);
          }
          &:hover {
            color: #ffbc3a;
          }
        }
        .collapsed-email-list-delete-icon {
          width: 5%;
          color: var(--cardborderColor);
          cursor: pointer;
          &:hover {
            color: red;
          }
        }
      }

      .collapsed-email-date {
        width: 20%;
        padding: 0 5px;
        font-weight: 700;
        text-align: end;
        line-height: 1.2;
      }
    }
  }

  .email-management-detail {
    width: 75%;
    padding: 0 5px 0 10px;
    transition: width 0.3s ease;
    overflow: hidden;
    display: none;
    &.collapsed {
      display: block;
      max-height: calc(100vh - 105px);
      overflow: hidden;
      overflow-y: auto;
      @media only screen and (max-width: 1560px) {
        max-height: calc(100vh - 95px);
      }
    }
    .email-detail-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 12px;
      border-bottom: 1px solid var(--cardborderColor);
      min-height: 54px !important;
      .email-detail-subject {
        font-size: var(--commonFont-large);
        font-weight: 500;
        .email-detail-info {
          margin-top: 5px;
          font-size: var(--commonFont-small);
          color: var(--textColor2);
          // display: flex;
        }
      }
      .email-detail-date {
        font-size: var(--commonFont-medium);
        white-space: nowrap;
        text-align: right;
      }
    }
    .email-detail-body {
      padding: 12px;
    }
    .email-detail-footer {
      padding: 10px 12px;
      border-top: 1px solid var(--cardborderColor);
      border-bottom: 1px solid var(--cardborderColor);
      .email-detail-footer-title {
        color: var(--themeColor);
        font-size: var(--commonFont);
        font-weight: 600;
      }
      .email-detail-footer-body {
        .attachment-container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .attachment-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 7px 0 4px 0;
            border: 1px solid var(--linkMenu);
            border-radius: 5px;
            padding: 4px 0px 4px 6px;
            .attachment-label {
              display: flex;
              align-items: center;
              width: calc(100% - 80px);
              // margin-right: 10px;
              img {
                width: 20px;
                margin-right: 10px;
              }
              .attachment-item-title {
                flex-grow: 1;
                font-size: var(--commonFont-medium);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .attachment-actions {
              display: flex;
              justify-content: center;
              gap: 10px;
              width: 80px;
              .btn-part {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                padding: 3px;
                display: flex;
                align-items: center;
                justify-content: center;

                .component-iconify {
                  width: 16px !important;
                  height: 16px !important;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                }

                &.pdf-view {
                  background: #f0effc;
                  color: var(--themeColor);
                }

                &.download-document {
                  background-color: #e6ffee;
                  color: #33cc33;
                }
              }
            }
          }
        }
        .no-attachment-found {
          border: 1px dashed var(--themeColor);
          border-radius: 10px;
          .record-not-found {
            img {
              width: 40px;
              @media only screen and (max-width: 1560px) {
                width: 45px;
              }
            }
          }
        }
      }
    }
  }
}

.sort-dropdown-container {
  position: relative;

  .sort-dropdown-toggle {
    background-color: var(--whiteColor);
    border: none;
    padding: 7px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &.open {
      background-color: #f0f0f0;
    }
  }

  .sort-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--whiteColor);
    border: 1px solid var(--cardborderColor);
    border-radius: 4px;
    margin-top: 5px;
    z-index: 1000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 150px;

    .sort-dropdown-section {
      padding: 8px 8px 3px;

      .sort-dropdown-title {
        font-weight: 500;
        margin-bottom: 6px;
        font-size: var(--commonFont-small);
        color: var(--textColor2);
      }

      .sort-dropdown-item {
        padding: 5px;
        font-size: var(--commonFont-average);
        cursor: pointer;
        transition: background-color 0.2s ease;
        display: flex;
        gap: 5px;
        margin-bottom: 5px;

        &:hover {
          background-color: #f2f2f2;
        }

        &.selected {
          background-color: var(--sidebar-btn-hover-bg);
          color: var(--theme-btn-hover-bg);
          font-weight: bold;
        }
        .tick-icon {
          min-width: 20px;
        }
      }
    }
  }
}

.filter-dropdown-container {
  position: relative;

  .filter-dropdown-toggle {
    background-color: var(--whiteColor);
    border: none;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &.open {
      background-color: #f0f0f0;
    }
  }

  .filter-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--whiteColor);
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 8px;
    z-index: 1000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 150px;

    .filter-dropdown-item {
      padding: 5px 10px;
      font-size: var(--commonFont-average);
      cursor: pointer;
      transition: background-color 0.2s ease;
      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: #f4f7f4;
      }

      &.selected {
        background-color: var(--sidebar-btn-hover-bg);
        color: var(--theme-btn-hover-bg);
        font-weight: bold;
      }
    }
  }
}

.comment-model {
  .modal-dialog {
    .modal-content {
      .modal-body {
        padding: 0 !important;
      }
    }
  }
}

