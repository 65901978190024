.shorting-section {
  position: relative;

  .shorting-desc-list {
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
    background: var(--whiteColor);
    border-radius: 5px;
    width: 210px;
    position: absolute;
    right: 0;
    top: 100%;
    display: none;
    z-index: 11;

    .short-types {
      .shorting-title {
        padding: 8px 10px;
        background: var(--bg-main-content);
        text-transform: uppercase;
        font-weight: 600;
        font-size: var(--commonFont-small);
        display: flex;
        align-items: center;
      }

      .shorting-inputs {
        padding: 15px 10px;
        max-height: calc(100vh - 380px);
          overflow: hidden;
          overflow-y: auto;

        .radio {
          .radio-buttons {
            margin: 0;
            margin-bottom: 20px;
            .radio-label{
              font-size: var(--commonFont-small);
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .checkbox {
          
          .input-checkbox {
            margin-bottom: 10px;
            .checkbox-part{
              align-items: flex-start;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
          .checkbox-part {
            .checkbox input + .checkbox-label:before,
            input[type=checkbox]:before,
            .checkbox input + .checkbox-label,
            .checkbox{
              max-height: 15px;
              max-width: 15px;
              min-height: 15px;
              min-width: 15px;
            }
            
            
            .input-label{
              font-size: var(--commonFont-small);
            }
          }
        }
      }
    }

    &.active {
      display: block;
    }
  }
}