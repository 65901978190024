.search-input {
  width: 450px;
  display: flex;
  align-items: center;
}

.search-section {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .left-desc-sec {
    width: 75%;
    padding-right: 25px;
  }

  .right-search-section {
    width: 25%;
  }
}

.search-part {
  position: relative;
  width: 100%;
  border: 1px solid var(--inputBorder);
  border-radius: 5px;
  overflow: hidden;

  input {
    border-radius: 2px !important;
    padding-left: 40px !important;
    border: none !important;
    font-size: var(--commonFont-small);
    font-weight: 500 !important;
    color: #656469 !important;

    &:focus {
      border: none !important;
      padding-left: 45px !important;
      box-shadow: none !important;
    }
  }

  .search-btn {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);

    .btn {
      background: transparent;
      min-width: auto;
      padding: 0;
      color: var(--textColor);
      font-weight: bolder;
      font-size: var(--commonFont);

      svg {
        width: 30px;
        filter: unset;
        -webkit-filter: unset;
        margin-right: 0;
      }
    }
  }
}

.serchbar-section {
  .card-section {
    .card-body-sec {
      margin-top: 0 !important;
    }
  }
}

// Card Searchbar
.card-top-title-btn {
  .manage-customer-dropdown{
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1750px) {
  .search-input {
    width: 312px;

    .search-section {
      .right-search-section {
        .btn {
          min-width: 115px;
        }
      }
    }
  }

  .search-part {
    input {
      font-size: var(--commonFont);
      height: 26px;
    }
  }
}
