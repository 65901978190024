//Dropdown Select Start
.custom-input {
  width: 100%;

}

.css-b62m3t-container {
  width: 100%;
}

.css-16xfy0z-control,
.css-13cymwt-control {
  box-shadow: none !important;
  border: 1px solid var(--inputBorder) !important;
  border-radius: 4px !important;
  width: 100%;
  min-height: 34px !important;
  height: 34px;
  overflow: hidden !important;
  overflow-y: auto !important;
  transition: 0.5s all !important;
}

.css-16xfy0z-control,
.css-12zf3ro-control {
  &:hover {
    border-color: var(--themeColor) !important;
    box-shadow: none !important;
    border-radius: 4px !important;
    width: 100%;
    height: 35px;
    box-shadow: 0 0 0.25rem 0.05rem rgba(105, 108, 255, 0.1) !important;
    // padding-left: 5px !important;
    transition: 0.5s all !important;
  }
}

.css-t3ipsp-control {
  height: 35px;
  box-shadow: none !important;
  border-color: var(--themeColor) !important;
  border: 1px solid var(--themeColor) !important;
  border-radius: 4px !important;
  box-shadow: 0 0 0.25rem 0.05rem rgba(105, 108, 255, 0.1) !important;
  // padding-left: 5px !important;
  transition: 0.5s all !important;
  overflow: hidden;
  overflow-y: auto;
}

.css-1hb7zxy-IndicatorsContainer {
  height: 32px !important;
  padding: 0 5px !important;

  .css-1xc3v61-indicatorContainer {
    padding: 5px !important;
  }
}

.basic-info-select {
  .css-1hb7zxy-IndicatorsContainer {
    height: 32px !important;
  }

  .css-t3ipsp-control,
  .css-13cymwt-control {
    min-height: 32px !important;
    height: 32px !important;
    padding-top: 0 !important;
  }
}

.css-1nmdiqs-menu {
  font-size: var(--commonFont-medium) !important;
  z-index: 99;
  cursor: pointer;

  .css-1n6sfyn-MenuList {
    cursor: pointer;

    .css-tr4s17-option {
      cursor: pointer;
    }
  }
}

.css-1jqq78o-placeholder {
  font-size: var(--commonFont-medium) !important;
  line-height: normal !important;
  color: var(--placeholderColor) !important;
}

//Dropdown Select End
.css-t3ipsp-control,
.css-13cymwt-control {
  min-height: 34px !important;
  height: 34px !important;
}

.css-12zf3ro-control {
  &:hover {
    min-height: 34px !important;
    height: 34px !important;
  }
}

.css-1xc3v61-indicatorContainer {
  padding: 5px !important;
}

.css-hlgwow,
.css-1fdsijx-ValueContainer {
  padding: 0 5px !important;
}

.css-15lsz6c-indicatorContainer {
  padding: 5px !important;
}

.css-1nmdiq5-menu {
  z-index: 99999 !important;
  cursor: pointer;
}

.css-t3ipsp-control {}

// .css-1dimb5e-singleValue{
//   width: 50px !important
// }
.css-1dimb5e-singleValue {
  font-size: var(--commonFont-medium);
}

.css-wsp0cs-MultiValueGeneric {
  font-size: var(--commonFont-medium) !important;
}

.customer-all-details {
  margin-top: 10px !important;

  .css-wsp0cs-MultiValueGeneric {
    padding: 0px 3px !important;

  }

  .css-13cymwt-control {
    min-height: auto !important;
    .css-1dyz3mf{
      padding: 0 !important;
    }
  }

  .css-1hb7zxy-IndicatorsContainer {
    height: 27px !important;
  }

  .css-3w2yfm-ValueContainer {
    padding-top: 5px !important;
  }

  .dis-dropdown {}

  .css-3iigni-container {
    .css-16xfy0z-control {
      border: none !important;
      background: transparent !important;
    }
  }

  .css-3w2yfm-ValueContainer {
    min-width: calc(100% - 30px);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.custom-checkbox-select {
  width: 100%;

  .css-1dimb5e-singleValue {
    display: flex;
    
  }
}

.css-1wy0on6 {
  align-items: flex-start !important;
}

@media only screen and (max-width: 1750px) {

  .css-16xfy0z-control,
  .css-13cymwt-control {
    height: 28px !important;
    min-height: 28px !important;
  }

  .css-t3ipsp-control,
  .css-16xfy0z-control,
  .css-12zf3ro-control {
    &:hover {
      height: 28px;
    }
  }

  .css-hlgwow,
  .css-1hb7zxy-IndicatorsContainer {
    height: 25px !important;
    padding: 0 5px !important;

    .css-1xc3v61-indicatorContainer {
      padding: 0px 5px 0 !important;
    }
  }

  .css-894a34-indicatorSeparator,
  .css-1u9des2-indicatorSeparator {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }

  .customer-all-details {
    .css-1hb7zxy-IndicatorsContainer {
      height: 25px !important;
    }
  }

  //Dropdown Select End
  .css-t3ipsp-control,
  .css-13cymwt-control {
    min-height: 28px !important;
    height: 28px !important;
  }

  .css-12zf3ro-control {
    &:hover {
      min-height: 28px !important;
      height: 28px !important;
    }
  }

  .css-1xc3v61-indicatorContainer {
    padding: 3px 5px !important;
  }

  .css-1fdsijx-ValueContainer {
    padding: 0px 5px !important;
  }

  .css-15lsz6c-indicatorContainer {
    padding: 3px 5px !important;
  }

  .css-3w2yfm-ValueContainer {
    padding: 0 8px !important;
  }

  .css-1dyz3mf,
  .css-hlgwow {
    padding: 0 5px !important;
  }
}


@media only screen and (max-width: 1650px) {

  .css-hlgwow,
  .css-1hb7zxy-IndicatorsContainer {
    height: 25px !important;
    padding: 0 5px !important;

    .css-1xc3v61-indicatorContainer {
      padding: 0px 5px 0 !important;
    }
  }

  .css-894a34-indicatorSeparator {
    .css-1u9des2-indicatorSeparator {
      margin-bottom: 4px !important;
      margin-top: 4px !important;
    }
  }

  .customer-all-details {
    .css-1hb7zxy-IndicatorsContainer {
      height: 25px !important;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .css-1hb7zxy-IndicatorsContainer {
    .css-1xc3v61-indicatorContainer {
      padding: 0 5px !important;
    }
  }
}

.status-dropdown {
  .custom-input {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      // background: url(../assets/images/editIcon-theme.png);
      background: url(../../../assets/images/editIcon-theme.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 16px;
      width: 16px;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      pointer-events: none;
    }
  }
}