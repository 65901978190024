.table-hover {
  table {
    tbody {
      tr {
        &:hover {
          background: #f2edf3b0;
        }

        td {}
      }
    }
  }
}

.table-striped {
  table {
    tbody {
      tr {
        // border: none;
        border-bottom: 1px solid var(--tableBorderColor) !important;
        // &:nth-child(even) {
        //   background: var(--themeColorLightGridRow);
        // }
      }
    }
  }
}

.table-bordered {
  table {
    thead {
      background: var(--themeColorLight);

      tr {
        th {
          border: 1px solid var(--inputBorder);
        }
      }
    }

    tbody {
      tr {
        td {
          border: 1px solid var(--inputBorder);
        }
      }
    }
  }
}

// Table border-table-simple End

//dynamic grid

// .dynamic-grid {
.final-molgrid-section {
  table {
    width: 100%;
    caption-side: bottom;
    border-collapse: collapse;
    overflow: hidden;

    thead {
      display: block;
      width: 100%;
      color: white !important;
      background: var(--themeColorLight);
      border-bottom: 1px solid var(--tableBorderColor);


      tr {
        display: flex;
        width: 100%;
        text-transform: capitalize;

        th {
          padding: 10px 10px 10px 19px;
          font-size: var(--commonFont);
          color: var(--blackColor);

          .heading-shorting {
            position: relative;

            .shorting-part {

              display: inline-block;

              button {
                background: transparent;
                border: none;
                outline: none;
                padding: 0 10px 0 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;

                span {
                  margin-right: 10px;
                  font-weight: 500;
                  padding: 0;
                  text-align: left;
                  text-transform: uppercase;
                  color: var(--blackColor);
                }

                img {
                  height: 14px;

                  &.order-icon {}
                }
              }

              span {
                &.sort-priority-number {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: var(--themeColor);
                  position: absolute;
                  right: -29px;
                  top: -9px;
                  color: var(--whiteColor);
                  height: 18px;
                  width: 30px;
                  line-height: 18px;
                  font-size: var(--commonFont-extraSmall);
                  border-radius: 4px;

                  .short-count {
                    display: flex;
                    align-items: center;
                    margin-left: 3px;

                    .cancel-short {
                      margin-left: 3px;
                      background: #ffffff9c;
                      width: 12px;
                      height: 12px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 5px;
                      transition: 0.5s all;
                      padding: 0;

                      i {
                        margin-left: 0;
                        margin-top: -1px;
                      }

                      &:hover {
                        background: var(--whiteColor);
                      }

                      img {
                        width: 6px;
                        height: 6px;
                      }
                    }
                  }
                }
              }

              &.short-column {
                button {
                  img {
                    &.order-icon {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }
          }

          &:last-child {
            width: 150px;
            text-align: center;
          }
        }
      }
    }

    tbody {
      display: block;
      max-height: calc(100vh - 395px);
      overflow-y: auto;
      width: 100%;
      overflow-x: hidden;

      tr {
        display: flex;
        width: 100%;
        border-bottom: 1px solid var(--inputBorder) !important;
        border-left: 0 !important;
        border-right: 0 !important;

        td {
          padding: 10px 10px 10px 19px;
          font-size: var(--commonFont-medium);
          align-self: center;

          &:last-child {
            // width: 150px;
            text-align: center;

            div {
              justify-content: center;

              a {
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }

          .dropdown-action {
            position: relative;
            width: 100%;
            display: flex;

            .btn {
              padding: 5px 7px;
              min-width: unset;
              background: #e0d9ff;

              img {
                margin: 0;
                width: 10px;
                filter: brightness(0);
                -webkit-filter: brightness(0);
              }
            }

            .dropdown-list {
              position: absolute;
              background: var(--whiteColor);
              width: 90px;
              /* border: 1px solid var(--inputBorder); */
              z-index: 11;
              right: 0;
              transform: translateX(-50%);
              top: 110%;
              padding: 7px 0;
              display: none;
              box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
              border-radius: 3px;

              ul {
                padding: 0;
                list-style-type: none;
                margin: 0;

                li {
                  text-align: left;

                  a {
                    display: block;
                    width: 100%;
                    padding: 0 15px;
                    // border: 1px solid var(--inputBorder);
                    font-size: var(--commonFont-medium);
                    font-weight: 400;
                    text-align: left;
                    color: var(--textColor);

                    &:hover {
                      color: var(--themeColor);
                    }
                  }
                }
              }
            }

            &.openAction {
              .dropdown-list {
                display: block;
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        border-left: 0 !important;
        border-right: 0 !important;

        td {
          &:last-child {
            a {
              cursor: pointer;

              img {
                min-height: 17px;
                min-width: 17px;
                max-height: 17px;
                max-width: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.5s all;
              }

              &:hover {
                img {
                  filter: brightness(0);
                }
              }
            }
          }

          span {
            border-radius: 2px;
            font-size: var(--commonFont-small);
            margin: 0 auto;
            padding: 4px 15px;
            text-align: center;
            margin: 0 auto;

            &.primary {
              background-color: var(--primaryColorLight);
              color: var(--primaryColor);
            }

            &.secondary {
              background-color: var(--secondaryColorLight);
              color: var(--secondaryColor);
            }

            &.danger {
              background-color: var(--dangerColorLight);
              color: var(--dangerColor);
            }

            &.warning {
              background-color: var(--warningColorLight);
              color: var(--warningColor);
            }

            &.info {
              background-color: var(--infoColorLight);
              color: var(--infoColor);
            }

            &.dark {
              background-color: var(--darkColorLight);
              color: var(--darkColor);
            }
          }
        }
      }
    }
  }
}

// }
.customer-list-all {
  table {
    tbody {
      max-height: calc(100vh - 365px);

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 315px);
      }

      @media only screen and (max-width: 1366px) {
        max-height: calc(100vh - 300px);
      }
    }
  }
}

.inactive-list-sec {
  table {
    tbody {
      max-height: calc(100vh - 355px);

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 305px);
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  .final-molgrid-section {
    table {
      thead {
        tr {
          th {
            // padding: 8px 10px 8px 15px;
            padding: 13px 20px;
          }
        }
      }

      tbody {
        tr {
          td {
            // padding: 7px 10px 7px 15px;
            padding: 13px 20px;

            &:last-child {
              a {
                img {
                  min-height: 16px;
                  min-width: 16px;
                  max-height: 16px;
                  max-width: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.input-list-button {
  .table-pagination {
    display: none !important;
  }
}