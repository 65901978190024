// Pagination Start
.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px 5px 5px;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 100%;
  }

  .display-per-page {
    font-weight: 600;
    font-size: 100%;

    select {
      cursor: pointer;
      margin-left: 10px;
      padding: 0 7px;
      width: 60px;
      height: 30px;
      border: 1.5px solid var(--themeColorLight);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      font-weight: bold;
      color: var(--themeColor);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: relative;
      background: url(../../../assets/images/arrow.png);
      background-position: center right;
      background-position-x: 40px;
      background-size: 10px 10px;
      background-repeat: no-repeat;
    }
  }

  button {
    height: 30px;
    width: 30px;
    border: 1.5px solid var(--themeColorLight);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--whiteColor);
    font-size: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

    img {
      width: 10px;

      &.left-arrow {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
      }

      &.right-arrow {
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
      }
    }

    &.active-button {
      background-color: var(--themeColor);
      color: var(--whiteColor);
      font-weight: 600;
      font-size: 100%;
      border: unset;
    }
  }
}

.new-input-page {
  border: 1px solid var(--inputBorder) !important;
  border-radius: 5px;
  text-align: center;
  height: 30px;
  width: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

// Pagination Ends