.filter-section {
  position: relative;
  z-index: 1;

  .button-sec {
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    .filter-icon-img {
      margin-right: 7px;
      width: 15px;
      filter: brightness(100);
    }

    span {
      font-weight: 600;
      font-size: var(--commonFont-medium);
    }
  }

  .filter-body-sec {
    background: var(--whiteColor);
    width: 800px;
    position: absolute;
    right: 0;
    top: 122%;
    box-shadow: 0px 6px 24px #1e357054;
    border-radius: 5px;
    display: none;

    .body-desc-part {
      position: relative;

      width: 100%;
      height: 100%;

      &::before {
        content: "";
        position: absolute;
        right: 5px;
        top: -9px;
        transform: translateX(-50%) rotate(180deg);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #000000db;

      }

      .filter-Heading {
        border-bottom: 1px solid var(--inputBorder);
        padding: 10px 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: linear-gradient(to right, #2d3641db, #000000db);

        .left-title {
          h4 {
            font-size: var(--commonFont-large);
            margin: 0;
            font-weight: bold;
            color: var(--whiteColor);
          }
        }

        .right-action {
          a {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: var(--whiteColor);
            font-weight: 600;

            img {
              width: 18px;
              margin-left: 5px;
              margin-top: 2px;
              filter: brightness(100);
            }
          }
        }
      }

      .filter-content {
        padding: 10px;

        .add-filter-btn {
          margin-right: 45px;
          button{
            margin-left: 0;
            background: var(--primaryColorLight);
          }
        }

        .filter-value-delete-icon {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .input-section {
            width: calc(100% - 45px);
          }

          .delete-icon {
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .delete-btn {
              cursor: pointer;
              padding: 5px;
              border-radius: 5px;
              transition: 0.5s all;
              min-width: 35px;
              min-height: 35px;
              max-width: 35px;
              max-height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: var(--primaryColorLight);

              img {
                filter: brightness(0);
                width: 20px;
              }

              &:hover {
                background: var(--dangerColorLight);

              }
            }
          }
        }

      }
    }
  }

  &.active-filter {
    .filter-body-sec {
      display: block;
    }
  }
}

@media only screen and (max-width: 1750px) {
  .filter-section {
    .filter-body-sec {
      width: 650px;
      .body-desc-part {
        .filter-Heading {
          padding: 7px 10px;
        }

        .filter-content {
          .filter-value-delete-icon {
            .delete-icon {
              .delete-btn {
                min-width: 27px;
                min-height: 27px;
                max-width: 27px;
                max-height: 27px;

                img {
                  width: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
  
}