// Pagination Start
.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px 5px 5px;

  p {
    margin: 0;
    font-weight: bold;
    font-size: var(--commonFont);
  }

  .display-per-page {
    font-weight: bold;
    font-size: var(--commonFont);

    select {
      cursor: pointer;
      margin-left: 10px;
      padding: 0 7px;
      width: 60px;
      height: 30px;
      border: 1.5px solid var(--inputBorder);
      border-radius: 3px;
      font-weight: bold;
      color: var(--themeColor);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: relative;
      background: url(../../../assets/images/arrow.png);
      background-position: center right;
      background-position-x: 40px;
      background-size: 10px 10px;
      background-repeat: no-repeat;
    }
  }

  button {
    height: 30px;
    width: 30px;
    border: 1.5px solid var(--inputBorder);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--whiteColor);

    img {
      width: 10px;

      &.left-arrow {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
      }

      &.right-arrow {
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
      }
    }

    &.active-button {
      background-color: var(--paginationActive);
      color: var(--whiteColor);
      font-size: var(--commonFont);
      font-weight: 600;
      border: unset;
      box-shadow: 0 .125rem .375rem 0 rgba(115, 103, 240, .3);
    }
  }

  .left-arrow{
    rotate: 90deg;
  }
  .right-arrow{
    rotate: 270deg;
  }
}

.new-input-page {
  border: 1px solid var(--inputBorder) !important;
  border-radius: 3px;
  text-align: center;
  height: 30px;
  width: 30px;
}

// Pagination Ends


@media only screen and (max-width: 1750px) {
  .table-pagination {
    button{
      height: 27px;
      width: 27px;
    }
    .display-per-page {
      select{
        padding: 5px 7px;
        height: 27px;
      }
    }
  }
  .new-input-page{
    height: 27px;
    width: 27px;
  }
  
}
