.input-validation {
  position: relative;
  height: 100%;
  text-align: left;
  word-break: break-all;
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // text-overflow: ellipsis;

  .validation-text {
    color: #fff;
    font-size: var(--commonFont-extraSmall);
    font-weight: 500;
    margin-left: 0px;
    position: absolute;
    top: 91%;
    right: 0;
    background: #000;
    padding: 2px 8px;
    width: 97%;
    text-align: left;
    border-radius: 4px;
    opacity: 70%;
    z-index: 11;

    &::after {
      content: "";
      position: absolute;
      top: -15px;
      right: 8px;
      border-width: 9px;
      border-style: solid;
      border-color: transparent transparent #000 transparent;
    }
  }
}

.validation-text {
  font-size: var(--commonFont-small);
}

.validation-star {
  color: #e30000;
  margin-left: 3px;
}

.checkbox-label-part {
  .validation-star {
    display: none;
  }
}

form {
  width: 100%;
  font-size: var(--commonFont);
}

@media only screen and (max-width: 1560px) {
  .input-field {
    height: 30px;
    padding: 0 5px;
  }
}

/* GridInput.scss */

.input-wrapper {
  position: relative;
}

.input-error .error-border {
  border: 1px solid #d32f2f !important;
}

.error-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #d32f2f;
  font-weight: bold;
  border: 1px solid #d32f2f;
  border-radius: 100%;
  padding: 0 5px;
  font-size: 9px;
}

.error-icon-number {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #d32f2f;
  font-weight: bold;
  border: 1px solid #d32f2f;
  border-radius: 100%;
  padding: 0 5px;
  font-size: 9px;
  background: var(--whiteColor);
}