.command-palette-backdrop {
  position: absolute;
  top: -2px;
  left: -6px;
  width: 100%;
  height: auto;
  background-color: transparent;
  z-index: 1000;
  font-weight: unset;
  color: var(--textColor);
  outline: none;
  border: none;
  cursor: unset !important;
}

.command-palette {
  background-color: var(--whiteColor);
  max-width: 700px;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .command-palette-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--cardborderColor);
    width: 100%;
    padding: 12px 10px;

    .search-input-bar {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 90%;

      .component-iconify {
        color: var(--themeColor);
      }

      .command-palette-search-input {
        width: 100%;
        border: none;
        outline: none;
        font-size: var(--commonFont-large);
        color: var(--textColor);
        width: 100%;

        &::placeholder {
          color: #5763758f;
        }
      }
    }

    .search-input-esc {
      font-size: var(--commonFont-medium);
      font-weight: bold;
      background-color: var(--whiteColor);
      border: 1px solid var(--cardborderColor);
      padding: 2px 6px;
      border-radius: 5px;
      color: var(--textColor);
      background: rgba(232, 234, 238, 0.5);
    }
  }

  .command-palette-list {
    min-height: 500px;
    max-height: 500px;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .main-search-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      padding: 0 10px 10px;
      width: 100%;
      height: fit-content;

      .command-palette-list-item {
        .command-palette-list-title {
          display: flex;
          align-items: center;
          padding: 16px 24px 6px 2px;
          font-size: var(--commonFont-small);
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1.5px;
          color: #989faa;
        }

        .command-palette-list-group {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .command-palette-list-btn {
            margin: 0;
            outline: none;
            background: transparent;
            display: flex;
            align-items: center;
            gap: 12px;
            border: 1px solid var(--cardborderColor);
            padding: 2px 0px 2px 16px;
            border-radius: 10px;
            height: 42px;
            font-weight: 500;
            font-size: var(--commonFont);
            color: var(--themeColor);
            background: #f6f7f840;

            &:hover {
              background-color: #e7e7ff91;
              border-color: var(--themeColor);
            }

            span {
              line-height: normal;
            }
          }
        }
      }
    }

    &.search-text-list {
      .module-group {
        text-align: left;
        padding: 10px;
        width: 100%;

        h3 {
          font-size: var(--commonFont);
          text-align: left;
          text-transform: uppercase;
          color: var(--themeColor);
          padding: 5px;
        }

        ul {
          padding: 0;
          margin: 0;

          li {
            list-style-type: none;
            cursor: pointer !important;

            .search-resuls {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 5px 10px 5px 15px;
              margin-bottom: 10px;
              border-radius: 8px;
              transition: 0.3s all;
              background: #f6f7f871;
              border: 1px solid #dfdfdf;
              cursor: default;
              .name-status {
                display: flex;
                justify-content: space-between;
                width: 100%;

                p {
                  margin: 0;
                  color: var(--textColor);
                  max-width: 80%;
                  overflow-x: hidden;
                  text-overflow: ellipsis;

                  &.status {
                    font-size: var(--commonFont-small);
                    color: var(--textColor2);
                    padding: 2px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;

                    &.warning {
                      background: var(--gradient-warning);
                      color: var(--whiteColor);
                    }

                    &.success {
                      background: var(--gradient-success);
                      color: var(--whiteColor);
                    }

                    &.info {
                      background: var(--gradient-info);
                      color: var(--whiteColor);
                    }

                    &.danger {
                      background: var(--gradient-danger);
                      color: var(--whiteColor);
                    }

                    &.blocked {
                      background: var(--blocked-theme);
                      color: var(--blocked-theme-btn);
                    }

                    &.frozen {
                      background: var(--frozen-theme);
                      color: var(--frozen-theme-btn);
                    }

                    &.approved {
                      background: var(--approved-theme);
                      color: var(--approved-theme-btn);
                    }

                    &.submitted {
                      background: var(--submitted-theme);
                      color: var(--submitted-theme-btn);
                    }

                    &.pending {
                      background: var(--pending-theme);
                      color: var(--pending-theme-btn);
                    }

                    &.disable {
                      background: var(--disabled-theme);
                      color: var(--disabled-theme-btn);
                    }

                    &.reject {
                      background: var(--reject-color);
                      color: var(--reject-theme-btn);
                    }
                  }
                }
              }
              .navigation-btn {
                border-radius: 30px;
                border: 1px solid var(--inputBorder);
                box-shadow: none;
                outline: none;
                font-size: var(--commonFont-small);
                padding: 0 15px;
              }
              &:hover {
                background: var(--commandPaleteBG);
                border-color: var(--themeColor);
                cursor: pointer !important;

                .name-status {
                  p {
                    margin: 0;
                    color: var(--themeColor);
                  }
                }
              }
            }
          }
        }
      }
      .no-results {
        width: 100%;
        .no-results-img {
          margin-top: 30px;
          img {
            width: 50px;
          }
        }
        .no-results-text {
          margin: 15px 30px;
          font-size: var(--commonFont-extraLarge);
          color: var(--textColor2);
          word-break: break-all;
        }
        .other-suggestions{
          // min-height: 400px;
          .other-title{
            font-size: var(--commonFont-large);
            margin-bottom: 5px;
          }
          .search-list{
            display: inline-block;
            margin: 0 auto;
            .search-item{
              outline: 0;
              background: transparent;
              border: 0;
              color: var(--themeColor);
              display: flex;
              align-items: center;
              justify-content: start;
              line-height: normal;
              gap: 3px;
              font-size: var(--commonFont-average);
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .command-palette-footer {
    padding: 12px 16px;
    border-top: 1px solid var(--cardborderColor);
    text-align: right;
    color: #5763758f;
    font-size: var(--commonFont-medium);

    span {
      color: var(--themeColor);
      font-size: var(--commonFont-large);
      font-weight: 600;
    }
  }
}
