.sidebar-section {
  background: var(--sidebarColor);
  box-shadow: var(--white-card-shadow);
  height: 100%;
  position: relative;
  width: 220px;
  height: 100vh;
  transition: 0.5s all;

  @media only screen and (max-width: 1366px) {
    width: 200px;
  }

  .sidebar {
    .main-menus {
      .sidebar-toggler {
        display: none;
      }

      svg {
        color: #acaab1;
      }

      .sidebar-brand {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 5px 4px;
        text-align: center;
        min-height: 60px;
        font-size: 28px;
        font-weight: 600;
        color: var(--whiteColor);
        //margin-left: 20px;
        margin-top: 10px;

        img {
          width: 60%;
          margin: a auto;
        }

        .brand-name {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .small-sidebar {
          display: block;
        }

        .sidebar-user {
          font-size: 12px;
          align-items: center;
          color: #b2b2b3;
        }
      }

      .sidebar-menu {
        padding: 0 15px;

        ul {
          list-style-type: none;
          padding-left: 0;

          &.sidebar-menu-list {
            margin-top: 15px;


            .sidebar-back-btn {
              background-color: #4d5061;
              .menu-item {
                a {
                  font-size: var(--commonFont-medium);
                  padding: 10px;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  transition: none;
                  .component-iconify {
                    svg {
                      color: var(--whiteColor);
                    }
                  }

                  span {
                    color: var(--whiteColor);
                  }
                  &:hover{
                    background-color: #393D53;
                  }
                }
              }
            }

            .menu-group {
              .group-label {
                color: #acaab1;
                padding: 3px 10px;
                font-weight: 400;
                font-size: var(--commonFont-medium);
                margin-top: 5px;
                text-transform: uppercase;
                // letter-spacing: .2px;
                white-space: nowrap;
                color: #acaab1;
              }

              li {
                &.menu-item {
                  margin-bottom: 5px;
                  a {
                    i {
                      color: var(--sidebarTextColor);
                    }

                    span {
                      color: var(--sidebarTextColor);
                    }
                  }

                  &:hover {
                    a {
                      background: var(--sidebarHoverColor);

                      i {
                        color: var(--sidebarHoverTextColor);
                      }

                      span {
                        color: var(--sidebarHoverTextColor);
                      }

                      svg {
                        color: var(--whiteColor);
                      }
                    }
                  }

                  &.active-menu {
                    .menu-arrow {
                      background: var(--sidebarActiveMenuColor);
                      box-shadow: none;
                      color: var(--whiteColor) !important;
                    }

                    a {
                      background: var(--gradient-sidebar);
                      box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
                      color: var(--whiteColor) !important;
                      text-decoration: none;

                      i {
                        color: var(--sidebarActiveTextColor);
                      }

                      span {
                        color: var(--sidebarActiveTextColor);
                      }

                      svg {
                        color: var(--whiteColor);
                      }
                    }
                  }

                  a {
                    font-size: var(--commonFont-medium);
                    color: var(--blackColor);
                    padding: 10px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;

                    i {
                      font-size: var(--commonFont-2extraLarge);
                      line-height: 18px;
                      color: #8291ae;
                    }

                    span {
                      padding-left: 8px;
                      font-size: var(--commonFont-medium);
                      font-weight: 400;
                      line-height: 1.467;
                    }

                    &.menu-arrow {
                      position: relative;

                      &::before {
                        content: "";
                        position: absolute;
                        height: 15px;
                        width: 15px;
                        background: url(../../../assets/images/arrow.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%) rotate(-90deg);
                        transition: 0.5s all;
                        filter: brightness(100);

                        @media only screen and (max-width: 1750px) {
                          height: 12px;
                          width: 12px;
                          right: 10px;
                        }
                      }
                    }
                  }

                  ul {
                    &.sidebar-dropdown {
                      padding-left: 35px;
                      height: 0;
                      overflow: hidden;
                      transition: 0.5s all;
                      -webkit-transition: 0.5s all;
                      -moz-transition: 0.5s all;
                      -ms-transition: 0.5s all;
                      -o-transition: 0.5s all;
                      // opacity: 0;

                      li {
                        a {
                          background: none;
                          width: 100%;
                          display: block;
                          opacity: 0.8;
                          padding: 10px 7px;
                          font-size: var(--commonFont-medium);
                          margin: 5px 0 2px;
                          transition: none;

                          &::before {
                            display: none;
                          }
                        }

                        &:hover {
                          a {
                            color: var(--themeColor);
                            // opacity: 1;
                          }
                        }

                        &:last-child {
                          a {
                            // padding-top: 0;
                          }
                        }

                        .active-submenu {
                          color: var(
                            --themeColor
                          ) !important; // Active color style
                        }
                      }
                    }
                  }

                  &.active-menu {
                    a {
                      &::before {
                        transform: translateY(-50%) rotate(0deg);
                        filter: brightness(100);
                      }
                    }

                    ul {
                      position: relative;

                      &.sidebar-dropdown {
                        height: auto;
                        opacity: 1;

                        li {
                          position: relative;

                          &:before {
                            position: absolute;
                            content: "";
                            border: solid #606060;
                            border-width: 0px 0px 1px 1px;
                            height: 55px;
                            width: 15px;
                            border-radius: 0px 0px 0px 8px;
                            top: -8%;
                            left: -15px;
                            transform: translateY(-50%);
                            transition: 0.5s all;
                          }

                          a {
                            color: var(--textColor);
                            box-shadow: none;
                            font-size: var(--commonFont-medium);
                            font-weight: 400;
                            text-decoration: none;
                          }

                          &:hover {
                            // &:before {
                            //   background: var(--themeColor);
                            // }

                            a {
                              color: var(--themeColor);
                              background-color: var(--sidebarActiveMenuColor);
                            }
                          }

                          .active-submenu {
                            background: linear-gradient(
                              270deg,
                              rgba(115, 103, 240, 0.7) 0%,
                              #7367f0 100%
                            );
                            box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
                            color: var(--whiteColor) !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .sidebar-section {
    position: absolute;
    z-index: 999;
  }

  .main-page-layout {
    .middle-page-section {
      width: 100%;
      margin-left: 80px;
    }
  }

  .collapsed {
    .tab-bg {
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        background-color: #00000050;
        z-index: 0;
        position: absolute;
        top: 0;
        left: -100%;
        transition: all 0.5s ease-in-out;
      }
    }
  }

  .tab-bg {
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: #00000050;
      z-index: 99;
      position: absolute;
      top: 0;
      left: 0%;
      transition: all 0.5s ease-in-out;
    }
  }
}
